

































import { CouponItem } from "../../Type/index"
import { Vue,Component,Prop } from "vue-property-decorator"

interface ListComponentType<T=string>{
    ActiveIcon:T;
    NotActiveIcon:T;
    HaveIcon:T;
    NotHaveIcon:T;
    StartTime:T;
    EndTime:T;
    handleToPath():void;
}

@Component({ name:"ListComponent" })
export default class ListComponent extends Vue implements ListComponentType{
    ActiveIcon = require("../../assets/icon/Coupon/CardListBackActive.png");
    NotActiveIcon = require("../../assets/icon/Coupon/CardListBackNot.png");
    HaveIcon = require("../../assets/icon/Coupon/HaveUsed.png");
    NotHaveIcon = require("../../assets/icon/Coupon/HaveOut.png");
    StartTime = ""
    EndTime = ""

    @Prop(Number)
    state!:number;

    get GetState():number{
        if( this.state && String(this.state).length ){
            return this.state | 0
        }else{
            return 0
        }
    }

    @Prop(Object)
    data!:CouponItem

    get GetData(){
        if( this.data.startDate ){
            this.StartTime = this.data.startDate.split(" ")[0];
        }
        if( this.data.endDate ){
            this.EndTime = this.data.endDate.split(" ")[0];
        }
        return this.data
    }

   handleToPath(){
       if( this.GetData.status == 0 ){
           switch( Number(this.GetData.type) ){
               case 1:
                   this.$router.push({
                       path:"/issue_Express"
                   })
                   break;
               case 2:
                   this.$router.push({
                       path:"/allStop",
                       query:{
                           title:"校园外卖",
                           state:"2"
                       }
                   })
                   break;
               case 3:
                   this.$router.push({
                       path:"/allStop",
                       query:{
                           title:"校园百货",
                           state:"3"
                       }
                   })
                   break;
               case 4:
                   this.$router.push({
                       path:"/allStop",
                       query:{
                           title:"零食铺子",
                           state:"1"
                       }
                   })
                   break;
           }
       }
   }
}
