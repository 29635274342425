





















































































import { Notify } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import List from "../../components/ShareComponent/PullDownUp.vue"
import ListComponent from "../../components/CouponComponent/ListComponent.vue"
import ListCom from "../../components/ShareComponent/ListBottom.vue"
import { Vue,Component } from "vue-property-decorator"
import Stroage from "../../util/Storage"
import { CouponListType as CouponListTypes } from "../../Api/Basics/InterfaceType"
import { CouponList as GetCouponList } from "../../Api/Basics/index"
import { CouponStore } from "../../store/modules/Basics/Coupon"
import { CouponItem } from "../../Type/index"
import { IsPageNo } from "../../util/filter"
import ZoomPage from "@/impView/PageSafety";

interface CouponListType<T=string>{
    userId:T|number;
    token:T;
    NavList:Array<string>
    NavIndex:number;
    UpData:CouponListTypes;
    List:Array<CouponItem>;
    UseList:Array<CouponItem>;
    NoList:Array<CouponItem>;
    NotList:Array<CouponItem>;
    UpDownBool:boolean;
    UpDown:boolean;
    loadingShow:boolean;

    handleChangeNav(index:number):void;
    handleUpDown(data?:any):void;
    handleUpData():void;
    handleFilterData(data:Array<CouponItem>):void;
    handleError(data:string):void;
}

@Component({ name:"CouponList",components:{ List,ListComponent,ListCom,HeadTop } })
export default class CouponList extends ZoomPage implements CouponListType{
    userId:string|number = "";
    token = "";
    NavList = ["未使用","已使用","已过期"]
    NavIndex = 0;
    UpData:CouponListTypes = {
        pageNo:1,
        pageSize:100
    }
    List:Array<CouponItem> = [];
    NoList: CouponItem<string,number>[] = []
    NotList: CouponItem<string,number>[] = []
    UseList: CouponItem<string,number>[] = []
    UpDownBool = false;
    UpDown = false;
    loadingShow = true

    mounted() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")

       if( CouponStore.GetCouponList && CouponStore.GetCouponList.length ){
           this.List = CouponStore.GetCouponList
           this.UpData.pageNo = IsPageNo(this.List.length,100)
           this.handleFilterData( this.List )
           this.loadingShow = false
       }else{
           this.handleUpData()
       }
    }

    handleChangeNav(index:number){
        if( this.NavIndex !== index ){
            this.NavIndex = index
            this.UpDownBool = false
        }
    }

    handleUpDown(data?:any){
        if( this.UpDownBool )return;
        this.UpData.pageNo += 1
        this.UpDown = true
        this.handleUpData()
    }

    handleUpData(){
        GetCouponList({
            userId:this.userId,
            token:this.token
        },this.UpData).then( (res:any)=>{
            this.UpDown = false
            this.loadingShow = false
            if( res.message.code === "200" ){
                if( res.data.length < this.UpData.pageSize ){
                    this.UpDownBool = true
                }
                this.handleFilterData( res.data )
                this.List = this.List.concat(res.data)
                CouponStore.SetCouponList(this.List)
            }else{
                this.handleError(res.message.msg)
            }
        } )
    }

    handleFilterData( data:Array<CouponItem> ){
       if ( !Array.isArray( data ) )return;
       data.forEach( (item,index)=>{  // status 0可以使用 1已使用 2过期
           switch ( Number( item.status ) ) {
               case 0:
                   this.UseList.push( item )
                   break;
               case 1:
                   this.NoList.push( item )
                   break;
               case 2:
                   this.NotList.push( item )
                   break;
           }
       } )
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }
}
